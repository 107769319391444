//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixinTeamOptions from "@/mixins/teamOptions";
import XHeader from "@/components/Header";
import baseView from "@/components/BaseView";
import XModal from "@/components/XModal";
import Report from "./reportDetail";

export default {
  mixins: [MixinTeamOptions],
  components: {
    // brief,
    XHeader,
    XModal,
    baseView,
    Report,
  },
  data() {
    return {
      functionName: "简报管理",
      teamValue: null, //选择的团队
      teamOptions: [],
      loading: false,
      datelist: [],
      active: 0,
      input: "",
      reportlist: [],
      userName: "",
      userPic: "",
      month: "",
      obj: {
        teamId: "",
        id: "",
        day: "",
      },
      title: "加班统计结果",
      totalTime: "", //总的加班时长
      workTimeList: [],
      usId: 0,
      searchData: {
        text: "",
        type: "",
      },
      paneLoad: false,
      searchType: "",
      searchStatuType: "",
      searchText: "",
      statuList: [
        {
          label: "简报状态",
          value: 2,
        },
        {
          label: "加班时长",
          value: 3,
        },
      ],
      paramMonth: "",
      FatherDetail: {},
      onlyOverWork: false,
      selectStatus: -1,
      reportStatuList: [
        {
          value: -1,
          name: "全部",
        },
        {
          value: 0,
          name: "未提交",
        },
        {
          value: 4,
          name: "待评审",
        },
        {
          value: 2,
          name: "已审批",
        },
        {
          value: 5,
          name: "被驳回",
        },
      ],
    };
  },
  filters: {
    dateFormat(day) {
      return day.timeFormat("MM月dd日");
    },
    formateOverTime(time) {
      if (!time) {
        return "无";
      } else {
        return parseInt(time) + "分钟";
      }
    },
    formateDate(date) {
      if (!date) {
        return;
      }
      return date.split("-")[0] + "年" + date.split("-")[1] + "月";
    },
    formatDate1(date) {
      let newDate = date.slice(0, 10);
      let newArr = newDate.split("-");
      return newArr[1] + "月" + newArr[2] + "日";
    },
    formateMonth(date) {
      if (date) {
        return date.split("/")[0] + "年" + date.split("/")[1];
      }
    },
    parseIntTime(time) {
      if (time == undefined) {
        return;
      }
      return parseInt(time);
    },
    formateWeek(day) {
      if (day == undefined) {
        return;
      }
      let week = new Date(day).getDay();
      switch (week) {
        case 0:
          return "星期天";
        case 1:
          return "星期一";
        case 2:
          return "星期二";
        case 3:
          return "星期三";
        case 4:
          return "星期四";
        case 5:
          return "星期五";
        case 6:
          return "星期六";
      }
    },
  },
  watch: {
    FatherDetail() {},
    obj() {},
    selectStatus() {},
  },
  methods: {
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.handleGetData();
      }
    },
    showDefaultReport() {
      this.loading = true;
      //未选择日期，默认加载当前月简报
      let _month = this.datelist[0].group;
      this.paramMonth = _month;
      this.$http
        .get("/Teams/Report/reportLook.ashx#", {
          params: {
            month: _month,
            teamId: this.teamValue,
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            this.paneLoad = false;
            this.usId = res.data.usId;
            this.reportlist = res.data.list;
            this.reportlist.forEach((item) => {
              if (
                (item.IsOverwork && item.InputOverTime && item.State !== 2) ||
                (item.IsOverwork && item.AuditOverTime && item.State == 2) ||
                (item.IsOverwork && item.AutoOverTimeApplied && item.State == 0)
              ) {
                item.flag = true;
              } else {
                item.flag = false;
              }
            });
            // console.log(this.reportlist)
            this.userName = res.data.usName;
            this.userPic = res.data.usPicture;
            this.month = this.reportlist[0].Day.substr(5, 2) + "月";
            if (this.month.substr(0, 1) == 0) {
              this.month = this.month.substr(1, 1) + "月";
            }
          }
        });
    },
    showReport(index, date) {
      //点击日期获得个人简报
      this.loading = true;
      this.active = index;
      this.paramMonth = date.group;
      if (index == this.datelist.length - 1) {
        this.$loadDate(-3, new Date());
        this.datelist = this.$xbb_dates;
      }
      this.searchReport();
    },
    calcTime(date) {
      //计算加班时间
      this.loading = false;
      this.$modal.show("calcTime");
      this.$http
        .get("/Teams/Report/GetUserOverTime.ashx", {
          params: {
            teamId: this.teamValue,
            month: date,
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.workTimeList = res.data.List;
            this.totalTime = res.data.totalTime;
          }
        });
    },
    toReportDes(day, index) {
      //改变详情页面参数
      this.obj = {
        teamId: this.teamValue,
        id: this.usId,
        day: day.split("T")[0],
      };
      this.$modal.show("report");
      this.FatherDetail = {
        list: this.reportlist,
        index,
        isSuccess: false,
        isApprove: false,
        time: 0,
        isReject: false,
        IsOverwork: true,
        endTime: "",
      };
    },
    getDay(day) {
      //监听详情页面日期的改变
      this.obj.day = day;
    },
    handleConfirm(value) {
      //关闭加班弹窗
      // this.showCalc=value
      this.$modal.hide("calcTime");
    },
    changeSearchStatu() {
      //切换搜索的状态
      this.searchReport();
    },
    changeReportStatu() {
      //切换简报的状态
      this.searchReport();
    },
    searchReport() {
      //搜索简报
      this.loading = true;
      this.$http
        .get("/Teams/Report/reportLook.ashx#", {
          params: {
            month: this.paramMonth,
            teamId: this.teamValue,
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            this.paneLoad = false;
            this.usId = res.data.usId;
            this.reportlist = res.data.list;
            this.reportlist.forEach((item) => {
              if (
                (item.IsOverwork && item.InputOverTime && item.State !== 2) ||
                (item.IsOverwork && item.AuditOverTime && item.State == 2) ||
                (item.IsOverwork && item.AutoOverTimeApplied && item.State == 0)
              ) {
                item.flag = true;
              } else {
                item.flag = false;
              }
            });
            this.userName = res.data.usName;
            this.userPic = res.data.usPicture;
            this.month = this.reportlist[0].Day.substr(5, 2) + "月";
            if (this.month.substr(0, 1) == 0) {
              this.month = this.month.substr(1, 1) + "月";
            }
          }
        });
    },
    getFromDetail(obj) {
      this.FatherDetail = obj;
      if (JSON.stringify(obj) == "{}" || obj.index == undefined) {
        this.showDefaultReport();
        // console.log('加载咯')
        return;
      }
      this.reportlist[obj.index].WorkEndTime = obj.endTime;
      this.reportlist[obj.index].IsOverwork = obj.IsOverwork;
      if (obj.isSuccess) {
        // console.log('自动审核通过')
        this.reportlist[obj.index].State = 2;
        this.reportlist[obj.index].AuditOverTime = obj.time;
      }
      if (!obj.isSuccess) {
        // console.log('自动未审核通过')
        this.reportlist[obj.index].State = 4;
        this.reportlist[obj.index].InputOverTime = obj.time;
        // console.log(this.reportlist)
      }
      if (obj.isReject) {
        // console.log('简报被驳回')
        this.reportlist[obj.index].State = 5;
        // console.log(this.reportlist)
      }
      if (obj.isApprove) {
        this.reportlist[obj.index].State = 2;
        this.reportlist[obj.index].AuditOverTime = obj.time;
      }
    },
    showDefaultMemberTime() {},
  },
  created() {
    // this.reportStatuList.push({
    // 	value: -1,
    // 	name: '全部'
    // })
    // this.$D.LIST('report_status').forEach(item => {
    // 	this.reportStatuList.push({
    // 		value: item.value,
    // 		name: item.name
    // 	})
    // })
  },
  mounted() {
    this.handleGetData = this.showDefaultReport;
    this.$loadDate(-17, new Date());
    this.datelist = this.$xbb_dates;
    // setTimeout(() => {
    //   this.showDefaultReport();
    // }, 2000);
    this.searchType = this.statuList[0].value;
    this.searchStatuType = this.reportStatuList[0].value;
    if (
      this.$route.params.teamId &&
      this.$route.params.id &&
      this.$route.params.date
    ) {
      this.obj = {
        teamId: this.$route.params.teamId,
        id: this.$route.params.id,
        day: this.$route.params.date,
      };
      this.teamValue = Number.parseInt(this.$route.params.teamId);
      this.usId = Number.parseInt(this.$route.params.id);
      this.$modal.show("report");
    }
    this.$nextTick(() => {
      const el = document.querySelector(".bv_left_ctn >ul");
      if (el) {
        const offsetHeight = el.offsetHeight;
        el.onscroll = () => {
          const scrollTop = el.scrollTop;
          const scrollHeight = el.scrollHeight;
          if (offsetHeight + scrollTop - scrollHeight >= -1) {
            // 需要执行的代码
            this.$loadDate(
              -2,
              this.datelist[this.datelist.length - 1].items[0]
            );
            this.datelist = this.$xbb_dates;
          }
        };
      }
    });
  },
};
