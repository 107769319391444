var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"myReport"}},[_c('baseView',{attrs:{"title_name":"简报列表"}},[_c('div',{attrs:{"slot":"panes"},slot:"panes"},[_c('el-select',{attrs:{"filterable":"","placeholder":"请先选择团队"},on:{"change":_vm.handleTeamChange},model:{value:(_vm.teamValue),callback:function ($$v) {_vm.teamValue=$$v},expression:"teamValue"}},_vm._l((_vm.teamOptions),function(item){return _c('el-option',{key:item.Id,attrs:{"label":item.Name,"value":item.Id}})}),1)],1),_c('i',{staticClass:"hiFont hi-report",staticStyle:{"margin-right":"1.5rem"},attrs:{"slot":"title_icon"},slot:"title_icon"}),_c('ul',{attrs:{"slot":"panes"},slot:"panes"},_vm._l((_vm.datelist),function(item,index){return _c('li',{key:index,class:_vm.active == index ? 'activeList' : '',on:{"click":function($event){return _vm.showReport(index, item)}}},[_c('span',[_vm._v(_vm._s(_vm._f("formateDate")(item.group)))]),_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.calcTime(item.group)}}},[_vm._v("统计加班")])])}),0),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"briefing",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"briefs"},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"name-left"},[(_vm.userPic)?_c('div',{staticClass:"img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$url + _vm.userPic),expression:"$url + userPic"}]})]):_c('span',{staticClass:"showName"},[_vm._v(_vm._s(_vm.userName))]),_c('span',[_vm._v(_vm._s(_vm.userName))])]),_c('div',{staticClass:"select-conditions"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function () {
                  _vm.onlyOverWork = !_vm.onlyOverWork;
                }}},[_vm._v(_vm._s(_vm.onlyOverWork ? "查看全部" : "仅查看加班"))]),_c('el-select',{model:{value:(_vm.selectStatus),callback:function ($$v) {_vm.selectStatus=$$v},expression:"selectStatus"}},_vm._l((_vm.reportStatuList),function(item,index){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)]),(_vm.reportlist.length > 0)?_c('div',{staticClass:"brief"},_vm._l((_vm.reportlist),function(item,index){return _c('div',{key:index,staticClass:"brief-item",on:{"click":function($event){return _vm.toReportDes(item.Day, index)}}},[_c('div',{staticClass:"left"},[_c('div',[_c('s',{staticClass:"hiFont hi-report"}),_c('span',[_vm._v(_vm._s(_vm._f("formatDate1")(item.Day)))]),_c('span',[_vm._v("  "+_vm._s(_vm._f("formateWeek")(item.Day)))]),(
                    (item.IsOverwork &&
                      (item.InputOverTime || item.AutoOverTimeApplied) &&
                      item.State !== 2) ||
                    (item.IsOverwork &&
                      item.AuditOverTime &&
                      item.State == 2) ||
                    (item.IsOverwork &&
                      item.AutoOverTimeApplied &&
                      item.State == 0)
                  )?_c('sub',[_vm._v("加班")]):_vm._e()]),(
                  (item.IsOverwork &&
                    (item.InputOverTime || item.AutoOverTimeApplied) &&
                    item.State !== 2) ||
                  (item.IsOverwork &&
                    item.AuditOverTime &&
                    item.State == 2) ||
                  (item.IsOverwork &&
                    item.AutoOverTimeApplied &&
                    item.State == 0)
                )?_c('div',[_c('p',[_vm._v(" 推荐加班时间："+_vm._s(_vm._f("formateOverTime")(item.AutoOverTimeApplied))+" ")]),(item.State !== 2)?_c('p',[_vm._v(" 填报加班时间："+_vm._s(_vm._f("formateOverTime")(item.InputOverTime))+" ")]):_c('p',[_vm._v(" 审批后加班时间："+_vm._s(_vm._f("formateOverTime")(item.AuditOverTime))+" ")])]):_c('div',{staticClass:"without-overwork"},[_c('p',[_vm._v("上班时间："+_vm._s(item.WorkStartTime))]),_c('p',[_vm._v("下班时间："+_vm._s(item.WorkEndTime))])])]),_c('div',{staticClass:"right"},[(item.State == 0)?_c('div',[_c('svg',{staticClass:"icon svg-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#hi-blt-wait-cr"}})]),_c('span',{staticStyle:{"color":"#ff7a45"}},[_vm._v("未提交")])]):_vm._e(),(item.State == 4 || item.State == 1)?_c('div',[_c('svg',{staticClass:"icon svg-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#hi-blt-approval-cr"}})]),_c('span',{staticStyle:{"color":"#13c2c2"}},[_vm._v("待评审")])]):_vm._e(),(item.State == 2)?_c('div',[_c('svg',{staticClass:"icon svg-icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#hi-blt-ok-cr"}})]),_c('span',{staticStyle:{"color":"#448ef5"}},[_vm._v("已评审")])]):_vm._e(),(item.State == 5)?_c('div',[_c('i',{staticClass:"hiFont hi-blt-wait-cr",staticStyle:{"color":"rgb(252, 76, 77)","font-size":"3rem","left":"0"}}),_c('span',{staticStyle:{"color":"rgb(252, 76, 77)"}},[_vm._v("被驳回")])]):_vm._e()]),_c('div',{staticClass:"item-cover",style:(_vm.onlyOverWork
                  ? _vm.onlyOverWork &&
                    item.flag &&
                    (item.State == _vm.selectStatus || _vm.selectStatus == -1)
                    ? 'opacity:0'
                    : 'opacity:.8'
                  : item.State == _vm.selectStatus || _vm.selectStatus == -1
                  ? 'opacity:0'
                  : 'opacity:.8')})])}),0):_c('div',{staticClass:"empty"},[_c('img',{attrs:{"src":require("../../assets/img/emptyRe.png")}}),_c('div',{staticClass:"img"}),_c('p',[_vm._v("暂无数据")])])])])]),_c('XModal',{attrs:{"name":"report","width":"95%","height":"95%","url":'/report/myReport/' + _vm.obj.teamId + '/' + _vm.obj.id + '/' + _vm.obj.day,"referer":"/report/myReport"}},[_c('Report',{attrs:{"obj":_vm.obj,"detail":_vm.FatherDetail},on:{"getDay":_vm.getDay,"sendReport":_vm.getFromDetail}})],1),_c('XModal',{attrs:{"title":_vm.title,"confirmBtnText":"确认","name":"calcTime","showCrossBtn":true,"height":"65%","isFixed":true},on:{"onConfirm":function($event){return _vm.handleConfirm()}}},[(_vm.workTimeList.length > 0)?_c('table',{staticClass:"calc-content"},[_c('tr',{staticStyle:{"font-weight":"bold"}},[_c('td',[_vm._v("日期("+_vm._s(_vm.month)+")")]),_c('td',[_vm._v("加班时长")])]),_vm._l((_vm.workTimeList),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm._f("dateFormat")(item.day)))]),_c('td',[_vm._v(_vm._s(item.time)+"h")])])}),_c('tr',[_c('td',[_vm._v("有效加班时长:")]),_c('td',[_vm._v(_vm._s(_vm.totalTime)+"小时")])])],2):_c('table',{staticClass:"calc-content"},[_c('tr',{staticStyle:{"font-weight":"bold"}},[_c('td',[_vm._v("日期("+_vm._s(_vm.month)+")")]),_c('td',[_vm._v("加班时长")])]),_c('tr',[_c('td',[_vm._v("该月没有加班记录")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }