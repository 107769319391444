//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixinTeamOptions from "@/mixins/teamOptions";
import XHeader from "@/components/Header";
import baseView from "@/components/BaseView";
import Report from "./reportDetail";
import XModal from "@/components/XModal";
import selMember from "@/components/Selectors/MemberSelectCopy";
// import selDepart from "@/components/Selectors/DepartmentSelector";
export default {
  mixins: [MixinTeamOptions],
  components: {
    XHeader,
    baseView,
    Report,
    XModal,
    selMember,
    // selDepart,
  },
  watch: {
    activeName() {
      if (this.activeName == "member") {
        this.type = 1;
      } else {
        this.type = 0;
      }
    },
    onlyOverWork() {
      console.log(this.onlyOverWork);
    },
  },
  filters: {
    formateTime(date) {
      if (!date) {
        return;
      }

      date = date.replace(/-/g, "/");
      return date.timeFormat("MM月dd日");
    },
    formatDate: function (date) {
      let newDate = date.slice(0, 10);
      let newArr = newDate.split("-");
      date = date.replace(/-/, "/");
      return newArr[1] + "月" + newArr[2] + "日";
    },
    formateMonth: function (date) {
      return date.timeFormat("yyyy年MM月");
    },
    formateMonthDate: function (date) {
      return date.timeFormat("yyyy年MM月dd日");
    },
    formateOverTime(time) {
      if (!time) {
        return "无";
      } else {
        return parseInt(time) + "分钟";
      }
    },
    parseIntTime(time) {
      if (time == undefined) {
        return;
      }
      return parseInt(time);
    },
    formateWeek(day) {
      if (day == undefined) {
        return;
      }
      let week = new Date(day).getDay();
      switch (week) {
        case 0:
          return "星期天";
        case 1:
          return "星期一";
        case 2:
          return "星期二";
        case 3:
          return "星期三";
        case 4:
          return "星期四";
        case 5:
          return "星期五";
        case 6:
          return "星期六";
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  props: {
    //是否是审批简报
    isApproval: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      functionName: "简报管理",
      teamValue: null, //选择的团队
      teamOptions: [],
      showTime: true,
      activeName: "time",
      url: this.$url,
      type: "0",
      memberList: [], //成员部门分类
      List: [], //成员视图列表
      uname: "", //成员视图用户名
      upic: "", //成员视图图片
      usId: "",
      dateList: [], //时间列表
      memberTimeList: [], //时间视图列表
      day: "",
      loading: false,
      searchData: {
        text: "",
        type: "",
        reportType: "",
      },
      tabbar: [
        {
          icon: "hiFont hi-clock-cr",
          value: "时间",
        },
        {
          icon: "hiFont hi-customer",
          value: "成员",
        },
      ],
      test: "", //当前选中的时间
      testId: "", //当前选中的成员id
      activeTime: "", //当前选中时间折叠板
      activeMember: "", //当前选中的成员折叠板
      reportStatuList: [
        {
          value: -1,
          name: "全部",
        },
        {
          value: 0,
          name: "未提交",
        },
        {
          value: 4,
          name: "待评审",
        },
        {
          value: 2,
          name: "已审批",
        },
        {
          value: 5,
          name: "被驳回",
        },
      ], //简报状态
      viewType: 0, //视图类型  默认时间视图
      timeStatuList: [
        {
          //时间视图搜索列表
          label: "综合",
          value: 0,
        },
        {
          label: "成员名称",
          value: 1,
        },
        {
          label: "部门名称",
          value: 2,
        },
        {
          label: "简报状态",
          value: 3,
        },
        {
          label: "加班时长",
          value: 4,
        },
      ],
      memberStatuList: [],
      obj: {
        id: "",
        day: "",
      },
      fatherDetail: {},
      activeDepart: "",
      selectStatus: -1,
      selMem: [],
      selDep: [],
      onlyOverWork: false,
      disabled: false,
      selMonth: new Date(),
      isFirstLoad: true,
      pageIndex: 1,
      pageSize: 15,
      pageCount: 1,
      busy: false,
      selReport: null,
    };
  },
  watch: {
    List() {
      // console.log(this.List)
    },
    fatherDetail() {},
  },
  methods: {
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.handleGetData();
      }
    },
    handleGetData() {
      if (!this.user) {
        return;
      }
      this.usId = this.user.UsId;
      this.testId = this.usId;
      this.getMemberCategry();
      this.showDefaultMemberTime();
    },
    scrollData() {
      console.log(arguments);
      if (this.teamValue) {
        if (this.viewType == 0) {
          this.showDefaultMemberTime();
        } else {
          this.loadMoreMonthReport();
        }
      }
    },
    /**
     * 简报审批成功数据刷新
     */
    handleApplyReport() {
      if (this.viewType == 0) {
        this.memberTimeList.forEach((e, index) => {
          if (e.day == this.selReport.day) {
            e.data = e.data.filter((ele) => ele.usId != this.selReport.usId);
            if (!e.data.length) {
              this.memberTimeList = this.memberTimeList.filter(
                (m, i) => index != i
              );
            }
          }
        });
      } else {
        this.showDefaultMember();
      }
    },
    handleClick(tab, event) {
      //时间视图和成员视图的切换
      this.onlyOverWork = false;
      if (this.activeName == "time") {
        this.showTime = true;
        this.viewType = 0;
        this.scrollTop(0);
        this.showDefaultMemberTime();
      } else if (this.activeName == "member") {
        this.List = [];
        this.showTime = false;
        this.viewType = 1;
        this.isFirstLoad = true;
        this.selMonth = new Date();
        this.showDefaultMember();
      }
    },
    getSelMember(arr) {
      this.selMem = arr;
      this.memberTimeList = [];
      this.pageIndex = 1;
      this.showDefaultMemberTime(true);
    },
    getDepart(arr) {
      this.selDep = arr;
      this.memberTimeList = [];
      this.pageIndex = 1;
      this.showDefaultMemberTime(true);
    },
    getMemberCategry() {
      //获得成员部门分类
      this.$http
        .get(
          `/Teams/Report/GetReportsVisibleUsers.ashx?teamId=${this.teamValue}`
        )
        .then((res) => {
          // console.log(res)
          if (res.res == 0) {
            this.memberList = res.data;
            var myReach = this.memberList.find((m) => m.IsMyReach);
            if (myReach) {
              this.activeMember = myReach.reach.RId;
            }
          }
        });
    },

    showMember(id) {
      //成员视图点击事件
      this.testId = id;
      this.usId = id;
      this.List = [];
      this.selMonth = new Date();
      this.isFirstLoad = true;
      this.showDefaultMember();
    },
    showDefaultMember() {
      //成员视图
      this.loading = true;
      this.busy = true;
      this.$http
        .get("/Teams/Report/reportsLook.ashx#", {
          params: {
            teamId: this.teamValue,
            type: 1,
            usId: this.usId,
            month: this.selMonth.toLocaleDateString(),
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            this.busy = false;
            this.uname = res.data.uName;
            this.upic = res.data.uPicture;
            let allFlag = true;
            res.data.Blist.forEach((val) => {
              if (
                (val.IsOverwork && val.InputOverTime && val.State !== 2) ||
                (val.IsOverwork && val.AuditOverTime && val.State == 2) ||
                (val.IsOverwork && val.AutoOverTimeApplied && val.State == 0)
              ) {
                val["flag"] = true; //加班任务
                allFlag = false;
              } else {
                val["flag"] = false;
              }
            });
            this.List.push({
              month: this.selMonth.toString(),
              listItem: res.data.Blist,
              allFlag: allFlag,
            });
            if (this.isFirstLoad) {
              console.log("one more month");
              this.loadMoreMonthReport();
              this.isFirstLoad = false;
            }
          }
        });
    },
    loadMoreMonthReport() {
      this.selMonth = new Date(
        new Date(this.List[this.List.length - 1].month) -
          1000 * 60 * 60 * 24 * 28
      );
      console.log(111);
      this.showDefaultMember();
    },
    selectMemberData() {
      if (this.selectStatus == -1) {
      }
    },
    showDefaultMemberTime(isReturn) {
      if (!isReturn && this.pageCount < this.pageIndex) {
        return;
      }
      // 时间视图 默认加载
      this.loading = true;
      this.busy = true;
      this.day = new Date()
        .toLocaleDateString()
        .slice(0, 7)
        .replace(/\//g, "-");
      let params = {
        teamId: this.teamValue,
        type: 0,
        date: this.activeTime,
        state: this.selectStatus,
        onlyOverWork: this.onlyOverWork,
        uid: this.selMem.map((item) => {
          return item.UsId;
        }),
        // rid: this.selDep.map((item) => {
        //   return item.RId;
        // }),
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        auditState: this.isApproval ? true : false,
      };
      this.$http.post("/Teams/Report/reportsLook.ashx#", params).then((res) => {
        if (res.res == 0) {
          this.loading = false;
          this.memberTimeList.push(...res.data.list);
          this.pageIndex = res.data.pageIndex;
          this.pageCount = res.data.pageCount;
          this.busy = false;
        }
      });
    },
    showReport(date) {
      //时间视图点击事件
      if (date) {
        this.test = date;
        this.day = date;
        this.activeTime = date.group;
        this.pageIndex = 1;
      }
      this.loading = true;
      this.busy = true;
      let params = {
        teamId: this.teamValue,
        type: 0,
        date: this.activeTime,
        state: this.selectStatus,
        onlyOverWork: this.onlyOverWork,
        uid: this.selMem.map((item) => {
          return item.UsId;
        }),
        // rid: this.selDep.map((item) => {
        //   return item.RId;
        // }),
        pageIndex: 1,
        pageSize: this.pageSize,
        auditState: this.isApproval ? true : false,
      };
      this.$http.post("/Teams/Report/reportsLook.ashx#", params).then((res) => {
        if (res.res == 0) {
          this.loading = false;
          this.scrollTop(0);
          this.memberTimeList = [];
          this.memberTimeList = res.data.list;
          this.pageIndex = res.data.pageIndex;
          this.pageCount = res.data.pageCount;
          this.busy = false;
        }
      });
    },
    /**
     * 定位到
     */
    scrollTop(distance) {
      this.$refs.brieftime.scroll({
        top: distance,
        behavior: "smooth",
      });
    },
    formateDate(date) {
      if (!date) {
        return;
      }
      return date.split("-")[0] + "年" + date.split("-")[1] + "月";
    },
    changeSearchStatu() {
      //搜索视图切换
      if (this.searchData.type == 3) {
        this.searchData.reportType = this.reportStatuList[0].value;
        this.searchReport();
      }
    },
    searchReport() {
      //时间视图搜索简报
      this.loading = true;
      this.day =
        this.day == ""
          ? new Date().toLocaleString().split(" ")[0].replace(/\//g, "-")
          : this.day;
      let query = "";
      if (this.searchData.type == 3) {
        query = this.searchData.reportType;
      } else {
        query = this.searchData.text;
      }
      this.$http
        .get("/Teams/Report/reportsLook.ashx#", {
          params: {
            teamId: this.teamValue,
            type: 0,
            date: this.day,
            qType: this.searchData.type,
            query: query,
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            this.memberTimeList = res.data.list;
          }
        });
    },
    //时间视图点击打开简报详情
    toReport(pp, index, fatherIndex) {
      this.obj = {
        teamId: this.teamValue,
        id: pp.usId,
        day: pp.lr.Day.split("T")[0],
      };
      if (this.obj.teamId && this.obj.id && this.obj.day) {
        this.selReport = pp;
        this.$modal.show("report");
      }
      this.fatherDetail = {
        list: this.memberTimeList,
        index,
        fatherIndex,
        isSuccess: false,
        isApprove: false,
        time: 0,
        isReject: false,
        IsOverwork: true,
        endTime: "",
      };
    },
    //成员视图点击打开简报详情
    toReportDes(day, index, fatherIndex) {
      this.obj = {
        teamId: this.teamValue,
        id: this.usId,
        day: day.split("T")[0],
      };
      this.fatherDetail = {
        list: this.List,
        index,
        fatherIndex,
        isSuccess: false,
        isApprove: false,
        isReject: false,
        IsOverwork: true,
        endTime: "",
      };
      if (this.obj.teamId && this.obj.id && this.obj.day) {
        this.$modal.show("report");
      }
    },
    //成员视图 点击月份
    clickMonth(month, index) {
      return false;
      let paramsMonth = "";
      let bool = false;
      for (let i = 0; i < month.length; i++) {
        if (month[i] == "月") {
          paramsMonth = month.replace(/年/, "/");
          paramsMonth = paramsMonth.replace(/月/, "/");
          paramsMonth = paramsMonth.replace(/日/, "");

          bool = true;
        }
      }
      if (!bool) {
        paramsMonth = new Date(month).toDateString();
      }

      if (this.List[index].listItem.length !== 0) {
        this.List[index].listItem.splice(0);
      } else {
        this.$http
          .get("/Teams/Report/reportsLook.ashx", {
            params: {
              type: 1,
              usId: this.usId,
              month: paramsMonth,
              qType: this.searchData.type,
              query: query,
            },
          })
          .then((res) => {
            if (res.res == 0) {
              this.List[index].listItem = res.data.Blist;
              if (this.List[index].listItem.length == 0) {
                this.List[index].isEmpty = true;
              } else {
                this.List[index].isEmpty = false;
              }

              let bool = false;
              this.List.forEach((item) => {
                if (
                  new Date(item.month).getTime() ==
                  new Date(this.List[index].month) - 1000 * 60 * 60 * 24 * 30
                ) {
                  bool = true;
                }
              });
              if (!bool) {
                this.List.push({
                  month: new Date(
                    new Date(this.List[index].month) - 1000 * 60 * 60 * 24 * 30
                  ).toString(),
                  listItem: [],
                  isEmpty: false,
                });
              }
            }
          });
      }
    },
    //成员视图改变搜索状态
    changeMemberSearchStatu() {
      if (this.searchData.type == 2) {
        this.searchData.reportType = this.reportStatuList[0].value;
        this.searchMemberReport();
      }
    },
    //获取成员视图列表
    searchMemberReport() {
      this.loading = true;
      this.$http
        .get("/Teams/Report/reportsLook.ashx#", {
          params: {
            teamId: this.teamValue,
            type: 1,
            usId: this.usId,
            month: new Date().toLocaleDateString(),
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            this.uname = res.data.uName;
            this.upic = res.data.uPicture;
            this.List = [];
            // if (!res.data.Blist.length) {
            // 	return
            // }

            if (res.data.Blist.length == 0) {
              this.List.push({
                month: new Date().toString(),
                listItem: res.data.Blist,
                isEmpty: true,
              });
            } else {
              this.List.push({
                month: new Date().toString(),
                listItem: res.data.Blist,
                isEmpty: false,
              });
            }
            let d = new Date();
            this.List.push({
              month: new Date(d.setMonth(d.getMonth() - 1)).toString(),
              listItem: [],
              isEmpty: false,
            });
          } else {
            this.loading = false;
            this.uname = "";
            this.upic = "";
            this.List = [];
          }
        });
    },
    getDay(day) {
      //详情页面简报翻页 更改日期 映射url
      this.obj.day = day;
    },
    getFromDetail(obj) {
      //详情页面操作成功 不请求接口刷新状态
      // console.log(obj)
      this.FatherDetail = obj;
      if (JSON.stringify(obj) == "{}" || obj.index == undefined) {
        if (this.viewType == 1) {
          this.searchMemberReport();
        } else {
          this.showDefaultMemberTime();
        }
        return;
      }
      if (this.viewType == 0) {
        console.log("时间视图");
        // console.log(obj)
        this.memberTimeList[obj.index].lr.WorkEndTime = obj.endTime;
        this.memberTimeList[obj.index].lr.IsOverwork = obj.IsOverwork;
        if (obj.isSuccess) {
          this.memberTimeList[obj.index].lr.State = 2;
          this.memberTimeList[obj.index].lr.AuditOverTime = obj.time;
        }
        if (!obj.isSuccess) {
          // console.log('自动未审核通过')
          this.memberTimeList[obj.index].lr.State = 4;
          this.memberTimeList[obj.index].lr.InputOverTime = obj.time;
        }
        if (obj.isReject) {
          // console.log('简报驳回')
          this.memberTimeList[obj.index].lr.State = 5;
        }
        if (obj.isApprove) {
          // 简报被审批通过
          this.memberTimeList[obj.index].lr.State = 2;
          this.memberTimeList[obj.index].lr.AuditOverTime = obj.time;
        }
      }
      if (this.viewType == 1) {
        this.List[obj.fatherIndex].listItem[obj.index].WorkEndTime =
          obj.endTime;
        this.List[obj.fatherIndex].listItem[obj.index].IsOverwork =
          obj.IsOverwork;
        if (obj.isSuccess) {
          // console.log('自动审核通过')
          // console.log(this.List[obj.fatherIndex])
          this.List[obj.fatherIndex].listItem[obj.index].State = 2;
          this.List[obj.fatherIndex].listItem[obj.index].AuditOverTime =
            obj.time;
        }
        if (!obj.isSuccess) {
          // console.log('自动未审核通过')
          this.List[obj.fatherIndex].listItem[obj.index].State = 4;
          this.List[obj.fatherIndex].listItem[obj.index].InputOverTime =
            obj.time;
        }
        if (obj.isReject) {
          // console.log('简报被驳回')
          this.List[obj.fatherIndex].listItem[obj.index].State = 5;
        }
        if (obj.isApprove) {
          this.List[obj.fatherIndex].listItem[obj.index].State = 2;
          this.List[obj.fatherIndex].listItem[obj.index].AuditOverTime =
            obj.time;
        }
      }
    },
    getActiveTime(date) {
      console.log(date);
    },
  },
  created() {
    this.$loadDate(-16, new Date());
    this.dateList = this.$xbb_dates;
    this.activeTime = new Date().timeFormat("yyyy-MM");
    // this.dateList[0].group.split("-")[0] +
    // "年" +
    // this.dateList[0].group.split("-")[1] +
    // "月";
    this.test = this.dateList[0].items[0];

    // console.log(this.activeTime);
  },
  mounted() {
    if (this.isApproval) {
      this.selectStatus = 4;
    }
    // this.usId = this.user.id;
    // this.testId = this.usId;
    // this.showDefaultMemberTime();
    this.searchData.type = this.timeStatuList[0].value;
    if (
      this.$route.params.teamId &&
      this.$route.params.id &&
      this.$route.params.date
    ) {
      this.obj = {
        teamId: this.$route.params.teamId,
        id: this.$route.params.id,
        day: this.$route.params.date,
      };
      this.teamValue = Number.parseInt(this.$route.params.teamId);
      this.usId = Number.parseInt(this.$route.params.id);
      this.selMem = [{ UsId: this.usId }];
      this.$modal.show("report");
    }
    this.$nextTick(() => {
      const el = document.querySelector(".el-tab-pane");
      const offsetHeight = el.offsetHeight;
      el.onscroll = () => {
        const scrollTop = el.scrollTop;
        const scrollHeight = el.scrollHeight;
        if (offsetHeight + scrollTop - scrollHeight >= -1) {
          // 需要执行的代码
          this.$loadDate(-2, this.dateList[this.dateList.length - 1].items[0]);
          this.dateList = this.$xbb_dates;
        }
      };
    });
  },
};
