//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import myReport from "./myReport.vue";
import memberReport from "./memberReport.vue";
export default {
  components: {
    Header: () => import("@/components/Header"),
    myReport,
    memberReport,
  },
  data() {
    return {
      tabList: [
        {
          value: "myReport",
          label: "我的简报",
        },
        {
          value: "memberReport",
          label: "成员简报",
        },
      ],
      reportKind: "myReport",
    };
  },
  methods: {
    /**
     * tab视图切换
     */
    tabChange(val) {
      this.$router.replace(`/report/${this.reportKind}`);
      this.reportKind = val;
    },
  },
  mounted() {
    if (this.$route.params.type == "memberReport") {
      this.reportKind = "memberReport";
    } else {
      this.reportKind = "myReport";
    }
  },
};
